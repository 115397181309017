const config = {
  forwoodId: {
    URL: 'https://id.dev.gromit.forwoodsafety.com',
    APP_CLIENT_ID: '1cst8ktoeaimog0fm9d98d2mn2'
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://0u9obhs4w3.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: '1.0.0',
    HOSTNAME: 'https://gromit-dev.dev.gromit.forwoodsafety.com',
    COMPANY_MICROFRONTEND_URL: 'YOUR_COMPANY_MICROFRONTEND_URL',
    PHYSICAL_LOCATION_MICROFRONTEND_URL: 'https://taxonomyphysicallocation.dev.gromit.forwoodsafety.com'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
