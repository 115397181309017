import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Layout, Menu, Icon } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import styles from './style.module.scss';

const { Sider } = Layout;

export class SideMenu extends PureComponent {
  constructor(props) {
    super(props);

    const { location } = this.props;

    this.state = {
      activePath: [location.pathname]
    };

    this.firstMount = true;
  }

  componentDidMount() {
    this.firstMount = false;
  }

  componentDidUpdate(nextProps) {
    const { location } = this.props;
    if (nextProps.location.pathname !== location.pathname) {
      this.setState({ activePath: [location.pathname] });
    }
  }

  onClick(containerName) {
    const { refreshMicroFrontend, isMobile, onCollapse } = this.props;
    refreshMicroFrontend(containerName);

    if (isMobile) {
      onCollapse(true);
    }
  }

  getMenuItem(menuItem) {
    const { t } = this.props;
    const {
      icon, title, path, container
    } = menuItem;
    return (
      <Menu.Item key={path}>
        <Link
          to={path}
          onClick={() => this.onClick(container)}
        >
          {icon}
          <span>{t(title)}</span>
        </Link>
      </Menu.Item>
    );
  }

  getNavMenuItems(menusData) {
    if (!menusData) {
      return [];
    }

    return menusData
      .map((item) => this.getMenuItem(item))
      .filter((item) => item);
  }

  render() {
    const {
      t,
      collapsed,
      onCollapse,
      isMobile,
      logo
    } = this.props;

    const { activePath } = this.state;

    const menuItem = [
      {
        container: 'CompanyMicroFrontend',
        icon: <Icon type="apartment" className={styles.icon} />,
        title: t('companies'),
        path: '/company'
      },
      {
        container: 'PhysicalLocationMicroFrontend',
        icon: <Icon type="pushpin" className={styles.icon} />,
        title: t('physicalLocations'),
        path: '/physical-location'
      }
    ];

    return (
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="lg"
        onCollapse={(collapse) => {
          if (this.firstMount || !isMobile) {
            onCollapse(collapse);
          }
        }}
        width={256}
        className={styles.sider}
      >
        <div className={styles.logo} id="logo">
          <Link to="/">
            <img src={logo} alt="logo" />
            <h1>Taxonomy</h1>
          </Link>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          onClick={({ keyPath }) => this.setState({ activePath: keyPath })}
          selectedKeys={activePath}
          className={styles.menuStyle}
        >
          {this.getNavMenuItems(menuItem)}
        </Menu>
      </Sider>
    );
  }
}

SideMenu.defaultProps = {
  collapsed: false
};

SideMenu.propTypes = {
  collapsed: PropTypes.bool,
  onCollapse: PropTypes.func.isRequired,
  logo: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  refreshMicroFrontend: PropTypes.func.isRequired
};

export default withRouter(withTranslation(['sideMenu'])(SideMenu));
