import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { withTranslation } from 'react-i18next';
import { Button, Spin } from 'antd';

import ResponsiveLayout from '../ResponsiveLayout';
import { routes } from '../../routes';
import { loader } from '../../sass/modules/loader.module.scss';

export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showError: false
    };
  }

  componentDidMount() {
    const { appConfig } = this.props;
    if (appConfig && appConfig.maintenanceMode === false) {
      this.handleAuth();
    }

    this.getAppConfig();
  }

  componentDidUpdate(prevProps) {
    const { auth, appConfig } = this.props;
    const { showError } = this.state;

    // Only call handleAuth() if the application loads with maintenanceMode
    // turned off or if it was previously on and now it's been turned off.
    if ((prevProps.appConfig
      && prevProps.appConfig.maintenanceMode === true
      && appConfig.maintenanceMode === false)
      || (prevProps.appConfig === null && appConfig && appConfig.maintenanceMode === false)) {
      this.handleAuth();
    }

    if (auth.error && !showError) {
      this.handleAuthError(auth.error);
    }
  }

  get loaderMessage() {
    const { auth } = this.props;

    if (auth.authorizing) {
      return 'redirecting';
    }

    if (this.getSearchParam('code') !== null) {
      return 'authorizing';
    }

    return null;
  }

  async getAppConfig() {
    const { getAppConfig } = this.props;
    await getAppConfig();
    this.appConfigInterval = setInterval(getAppConfig.bind(this), 60 * 1000);
  }

  getSearchParam(param) {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    if (params.has(param)) {
      return params.get(param);
    }

    return null;
  }

  async handleAuth() {
    const { handleAuthSession, exchangeCodeForTokens } = this.props;

    const code = this.getSearchParam('code');
    const state = this.getSearchParam('state');

    // We are not currently going through the authorization flow.
    // Call handleAuthSession to use an existing session or begin a new one.
    if (!code) {
      return handleAuthSession();
    }

    // Auth code flow in progress. Invoke token exchange
    await exchangeCodeForTokens(code, state);

    return this.redirect();
  }

  redirect() {
    const { history } = this.props;
    const pathname = sessionStorage.getItem('lastPath') || '/';
    sessionStorage.removeItem('lastPath');

    return history.push(pathname);
  }

  handleAuthError() {
    this.setState({
      showError: true
    });
  }

  render() {
    const { showError } = this.state;
    const {
      t, appConfig, auth = {}, authorize
    } = this.props;

    const { error, accessToken } = auth;

    console.log("test")
    console.log(error)

    if (this.loaderMessage) {
      return (
        <Spin
          className={loader}
          size="large"
          tip={t(this.loaderMessage)}
        >
          <div />
        </Spin>
      );
    }

    if (!accessToken) {
      return null;
    }

    return (
      <ResponsiveLayout>
        {renderRoutes(routes)}
      </ResponsiveLayout>
    );
  }
}

App.defaultProps = {
  auth: {},
  appConfig: null
};

App.propTypes = {
  auth: PropTypes.object,
  handleAuthSession: PropTypes.func.isRequired,
  authorize: PropTypes.func.isRequired,
  exchangeCodeForTokens: PropTypes.func.isRequired,
  appConfig: PropTypes.object,
  getAppConfig: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation(['common', 'errors', 'maintenanceMode'])(withRouter(App));
