import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { withTranslation } from 'react-i18next';
import { ContainerQuery } from 'react-container-query';
import Media from 'react-media';
import classNames from 'classnames';

import ResponsiveHeader from '../../Containers/ResponsiveHeader';
import ResponsiveSideMenu from '../ResponsiveSideMenu';

import forwoodLogo from '../../images/forwood-logo-blue-on-light.svg';
import forwoodLogoLight from '../../images/forwood-logo-cut-out-light.svg';

import styles from './style.module.scss';

const { Footer, Content } = Layout;

const query = {
  'screen-xs': {
    maxWidth: 575
  },
  'screen-sm': {
    minWidth: 576,
    maxWidth: 767
  },
  'screen-md': {
    minWidth: 768,
    maxWidth: 991
  },
  'screen-lg': {
    minWidth: 992,
    maxWidth: 1199
  },
  'screen-xl': {
    minWidth: 1200,
    maxWidth: 1599
  },
  'screen-xxl': {
    minWidth: 1600
  }
};

class ResponsiveLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false
    };

    this.onCollapse = this.onCollapse.bind(this);
  }

  onCollapse(collapsed) {
    this.setState({ collapsed });
  }

  render() {
    const { children, t } = this.props;
    const { collapsed } = this.state;

    return (
      <Media query="(max-width: 769px)">
        {(isMobile) => (
          <ContainerQuery query={query}>
            {(params) => (
              <div className={classNames(params)}>
                <Layout>
                  <ResponsiveSideMenu
                    isMobile={isMobile}
                    onCollapse={this.onCollapse}
                    collapsed={collapsed}
                    logo={forwoodLogoLight}
                  />
                  <Layout className={styles.innerLayout}>
                    <ResponsiveHeader
                      isMobile={isMobile}
                      onCollapse={this.onCollapse}
                      collapsed={collapsed}
                      logo={forwoodLogo}
                    />
                    <Content className={styles.content}>
                      {children}
                    </Content>
                    <Footer className={styles.footer}>
                      {t('copyright', { year: (new Date()).getFullYear() })}
                    </Footer>
                  </Layout>
                </Layout>
              </div>
            )}
          </ContainerQuery>
        )}
      </Media>
    );
  }
}

ResponsiveLayout.defaultProps = {
  children: null
};

ResponsiveLayout.propTypes = {
  children: PropTypes.any,
  t: PropTypes.func.isRequired
};

export default withTranslation(['common'])(ResponsiveLayout);
