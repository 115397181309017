import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  Icon, Layout, Button, Menu, Dropdown
} from 'antd';

import LanguageSelector from '../LanguageSelector';
import styles from './style.module.scss';

const { Header } = Layout;

class ResponsiveHeader extends PureComponent {
  static triggerResizeEvent() {
    const event = document.createEvent('HTMLEvents');
    event.initEvent('resize', true, false);
    window.dispatchEvent(event);
  }

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  signOut() {
    const { logout } = this.props;
    logout();
  }

  toggle() {
    const { collapsed, onCollapse } = this.props;
    onCollapse(!collapsed);
    ResponsiveHeader.triggerResizeEvent();
  }

  greeting() {
    const { t, isMobile, userEmail } = this.props;

    const menu = (
      <Menu>
        <Menu.Item
          className="logout"
          onClick={this.signOut}
        >
          {t('common:signOut')}
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown
        overlayStyle={{ zIndex: 10000001 }}
        overlay={menu}
        trigger={['click']}
        className="greetings"
      >
        {isMobile ? (
          <Button size="large" shape="circle">
            <Icon type="user" />
          </Button>
        ) : (
          <Button className="current-user" size="large">
            <Icon type="user" />
            {userEmail}
          </Button>
        )}
      </Dropdown>
    );
  }

  render() {
    const { collapsed, isMobile, logo } = this.props;
    return (
      <Header style={{ padding: 0 }}>
        <div className={styles.header}>
          {isMobile && (
            <Link to="/" className={styles.logo} key="logo">
              <img src={logo} alt="logo" width="32" />
            </Link>
          )}
          <span className={styles.trigger} onClick={this.toggle}>
            <Icon type={collapsed ? 'menu-unfold' : 'menu-fold'} />
          </span>
          <div className={styles.right}>
            <LanguageSelector
              isMobile={isMobile}
            />
            {this.greeting()}
          </div>
        </div>
      </Header>
    );
  }
}

ResponsiveHeader.defaultProps = {
  collapsed: false
};

ResponsiveHeader.propTypes = {
  logout: PropTypes.func.isRequired,
  collapsed: PropTypes.bool,
  onCollapse: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  userEmail: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation(['common'])(ResponsiveHeader);
