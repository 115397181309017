import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Icon, Dropdown, Menu, Button
} from 'antd';

import { languageOptions, getCurrentLanguage } from '../../translations/languageOptions';
import styles from './style.module.scss';

class LanguageSelector extends PureComponent {
  setLanguage(value) {
    const { i18n } = this.props;
    i18n.changeLanguage(value);
  }

  languageMenuItem(language) {
    const { t } = this.props;
    return (
      <Menu.Item key={language.key}>
        {t(language.translationKey)}
      </Menu.Item>
    );
  }

  languageMenuItems(selectedLanguageOption) {
    return (
      <Menu
        onClick={(o) => this.setLanguage(o.key)}
        selectedKeys={[selectedLanguageOption.key]}
      >
        {languageOptions.map((language) => (this.languageMenuItem(language)))}
      </Menu>
    );
  }

  render() {
    const { isMobile, i18n, t } = this.props;

    let selectedLanguageOption = null;
    if (getCurrentLanguage(i18n.languages)) {
      selectedLanguageOption = getCurrentLanguage(i18n.languages);
    }

    const menu = this.languageMenuItems(selectedLanguageOption);

    return (
      <Dropdown
        overlayStyle={{ zIndex: 10000001 }}
        overlay={menu}
        trigger={['click']}
        className={styles.dropdown}
      >
        {isMobile ? (
          <Button size="large" shape="circle">
            <Icon type="global" />
          </Button>
        ) : (
          <Button size="large">
            <Icon type="global" />
            {selectedLanguageOption ? t(selectedLanguageOption.translationKey) : t('language:language')}
          </Button>
        )}
      </Dropdown>
    );
  }
}

LanguageSelector.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation(['language'])(LanguageSelector);
