import { connect } from 'react-redux';

import SideMenu from '../../Components/SideMenu';
import { refreshMicroFrontend } from '../../reducers/microFrontend';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  refreshMicroFrontend(...args) {
    return refreshMicroFrontend(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
