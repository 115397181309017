import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';
import SideMenu from '../../Containers/SideMenu';
import styles from './style.module.scss';

class ResponsiveSideMenu extends PureComponent {
  render() {
    const {
      isMobile,
      collapsed,
      onCollapse,
      logo
    } = this.props;

    return isMobile ? (
      <Drawer
        visible={!collapsed}
        placement="left"
        onClose={() => onCollapse(true)}
        className={styles.drawerStyle}
      >
        <SideMenu
          isMobile={isMobile}
          onCollapse={onCollapse}
          collapsed={false}
          logo={logo}
        />
      </Drawer>
    ) : (
      <SideMenu
        isMobile={isMobile}
        onCollapse={onCollapse}
        collapsed={collapsed}
        logo={logo}
      />
    );
  }
}

ResponsiveSideMenu.defaultProps = {
  collapsed: false
};

ResponsiveSideMenu.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  collapsed: PropTypes.bool,
  onCollapse: PropTypes.func.isRequired,
  logo: PropTypes.string.isRequired
};

export default ResponsiveSideMenu;
