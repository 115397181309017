import { connect } from 'react-redux';
import ResponsiveHeader from '../../Components/ResponsiveHeader';
import { logout } from '../../reducers/auth';

const mapStateToProps = (state, ownProps) => ({
  userEmail: state.auth.idToken.email,
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  logout(...args) {
    return logout(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveHeader);
